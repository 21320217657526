<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="项目名称" prop="projectName" >
        <a-input v-model="form.projectName" placeholder="请输入项目名称" />
      </a-form-model-item>
      <a-form-model-item label="项目介绍" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <a-form-model-item label="项目类型" prop="type" >
        <a-select placeholder="项目类型" v-model="form.type">
          <a-select-option :key="0" :value="0">普通项目</a-select-option>
          <a-select-option :key="1" :value="1">特殊项目</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="上线时间" prop="uptime" >
        <a-date-picker style="width: 100%" v-model="form.uptime" format="YYYY-MM-DD" @change="onChangeDate" allow-clear/>
      </a-form-model-item>
<!--      <a-form-model-item label="融资金额" prop="financingAmountSelect" >-->
<!--        <a-input v-model="form.financingAmountSelect" placeholder="请输入融资金额" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="融资金额" prop="financingAmount" >-->
<!--        <a-input v-model="form.financingAmount" placeholder="请输入融资金额" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="融资阶段" prop="financingStage" >-->
<!--        <a-input v-model="form.financingStage" placeholder="请输入融资阶段" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="服务项目" prop="serviceItems" >
        <a-input v-model="form.serviceItems" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
<!--      <a-form-model-item label="是否有顾问" prop="isMentor" >-->
<!--        <a-select placeholder="请选择是否有顾问" v-model="form.isMentor">-->
<!--          <a-select-option v-for="(d, index) in isMentorOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="顾问ID" prop="mentorUserId" >-->
<!--        <a-input v-model="form.mentorUserId" placeholder="请输入顾问ID" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="是否有分析师" prop="isAnalyst" >
        <a-select placeholder="请选择是否有分析师" v-model="form.isAnalyst">
          <a-select-option :key="0" :value="0">否</a-select-option>
          <a-select-option :key="1" :value="1">是</a-select-option>
<!--          <a-select-option v-for="(d, index) in isAnalystOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="分析师ID" prop="analystUserId" >
        <a-input v-model="form.analystUserId" placeholder="请输入分析师ID" />
      </a-form-model-item>
<!--      <a-form-model-item label="是否推广曝光" prop="isPromotion" >-->
<!--        <a-select placeholder="请选择是否推广曝光" v-model="form.isPromotion">-->
<!--          <a-select-option v-for="(d, index) in isPromotionOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="推广曝光到期时间" prop="promotionEndTime" >-->
<!--        <a-date-picker style="width: 100%" v-model="form.promotionEndTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="项目状态" prop="status" >-->
<!--        <a-select placeholder="请选择项目状态" v-model="form.status">-->
<!--          <a-select-option v-for="(d, index) in statusOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="业务标签" prop="businessTag" >-->
<!--        <a-input v-model="form.businessTag" placeholder="请输入业务标签" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="业务标签" prop="businessTagValue" >
        <a-input v-model="form.businessTagValue" placeholder="请输入业务标签" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getProject, addProject, updateProject } from '@/api/biz/project'

export default {
  name: 'CreateForm',
  props: {
    typeOptions: {
      type: Array,
      required: true
    },
    isMentorOptions: {
      type: Array,
      required: true
    },
    isAnalystOptions: {
      type: Array,
      required: true
    },
    isPromotionOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        projectName: null,

        type: null,

        uptime: null,

        financingAmountSelect: null,

        financingAmount: null,

        financingStage: null,

        serviceItems: null,

        isMentor: null,

        mentorUserId: null,

        isAnalyst: null,

        analystUserId: null,

        isPromotion: null,

        promotionEndTime: null,

        status: null,

        createTime: null,

        remark: null,

        businessTag: null,

        businessTagValue: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        projectName: null,
        type: null,
        uptime: null,
        financingAmountSelect: null,
        financingAmount: null,
        financingStage: null,
        serviceItems: null,
        isMentor: null,
        mentorUserId: null,
        isAnalyst: null,
        analystUserId: null,
        isPromotion: null,
        promotionEndTime: null,
        status: null,
        createTime: null,
        remark: null,
        businessTag: null,
        businessTagValue: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getProject({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    onChangeDate(value, dateString) {
      debugger
      this.form.uptime = dateString;
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateProject(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addProject(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
