import request from '@/utils/request'


// 查询项目列表
export function listProject(query) {
  return request({
    url: '/biz/project/list',
    method: 'get',
    params: query
  })
}

// 查询项目分页
export function pageProject(query) {
  return request({
    url: '/biz/project/page',
    method: 'get',
    params: query
  })
}

// 查询项目详细
export function getProject(data) {
  return request({
    url: '/biz/project/detail',
    method: 'get',
    params: data
  })
}

// 新增项目
export function addProject(data) {
  return request({
    url: '/biz/project/add',
    method: 'post',
    data: data
  })
}

// 修改项目
export function updateProject(data) {
  return request({
    url: '/biz/project/edit',
    method: 'post',
    data: data
  })
}

// 删除项目
export function delProject(data) {
  return request({
    url: '/biz/project/delete',
    method: 'post',
    data: data
  })
}
